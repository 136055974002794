<div class="page-container">
  <app-header title="Name" (handleBackClick)="onBackClick()"></app-header>
  <div *ngIf="spinnerService.visibility | async; else content" class="loading">
    <div class="loader"></div>
  </div>
  <ng-template #content>
    <div class="form-container" [ngClass]="{'form-loading': isLoading}">
      <div *ngIf="isLoading" class="chat-loader-container">
        <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
      </div>
      <ng-container *ngIf="user">
        <form [formGroup]="userNameForm" (ngSubmit)="onSubmit()">
          <div>
            <span class="input-label">First Name</span>
            <mat-form-field>
              <input matInput id="firstName" formControlName="firstName" autocomplete="off">
            </mat-form-field>
          </div>
          <div>
            <span class="input-label">Last Name</span>
            <mat-form-field>
              <input matInput id="lastName" formControlName="lastName" autocomplete="off">
            </mat-form-field>
          </div>
          <div>
            <p class="input-label" style="margin: 0">Display Name</p>
            <p style="color: #7E7E7E; font-weight: 400; font-size: 14px; margin: 0">If different from first name</p>
            <mat-form-field>
              <input matInput id="displayName" formControlName="displayName" autocomplete="off">
            </mat-form-field>
          </div>
          <div class="submit-button-container">
            <app-custom-button type="submit" title="Save" variant="primary" [disabled]="!userNameForm.valid || !hasFormChanged()" (click)="onSubmit()"></app-custom-button>
          </div>
        </form>
      </ng-container>
    </div>
  </ng-template>
</div>
