<div class="page-container">
  <app-header title="Units" (handleBackClick)="onBackClick()"></app-header>
  <div class="form-container" [ngClass]="{'form-loading': isLoading}">
    <div *ngIf="isLoading" class="chat-loader-container">
      <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    </div>
    <ng-container *ngIf="user">
      <form [formGroup]="unitForm" (ngSubmit)="onSubmit()">
        <div>
          <p class="section-title">Measure system</p>
          <div class="card">
            <div class="item">
              <mat-radio-group id="unitSystem" formControlName="unitSystem">
                <mat-radio-button class="radio-button" *ngFor="let unit of unitSystems" [value]="unit.value" labelPosition="before">
                  <div class="radio-content">
                    {{ unit.option }}
                    <span style="position: absolute; right: 16px">
                    <mat-icon *ngIf="unitForm.get('unitSystem')?.value === unit.value" class="check-icon">check</mat-icon>
                  </span>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div style="margin-top: 24px">
            <p class="section-title">Date</p>
            <div class="card">
              <div class="item">
                <mat-radio-group formControlName="dateFormat">
                  <mat-radio-button class="radio-button" *ngFor="let dateFormat of dateFormats" [value]="dateFormat.value">
                    <div class="radio-content">
                      {{ dateFormat.option }}
                      <span style="position: absolute; right: 16px">
                      <mat-icon *ngIf="unitForm.get('dateFormat')?.value === dateFormat.value" class="check-icon">check</mat-icon>
                    </span>
                    </div>
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" style="display: none" #hiddenSubmitButton></button>
      </form>
      <div class="submit-button-container">
        <app-custom-button type="submit" title="Save" variant="primary" [disabled]="!unitForm.valid || !hasFormChanged()" (click)="onCustomButtonClick()"></app-custom-button>
      </div>
    </ng-container>
  </div>
</div>
