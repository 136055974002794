import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatIconButton } from "@angular/material/button";
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbar } from "@angular/material/toolbar";
import { NgClass, NgIf, NgStyle } from "@angular/common";
import { RACE_ID, RACE_LOGO_URL, RACE_SITE_URL } from "../constants";
import { ScreenResizeService } from "./services/screenResive/screen-resize.service";
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [MatTabsModule, MatIconModule, RouterOutlet, MatIconButton, RouterLink, RouterLinkActive, MatToolbar, NgIf, NgStyle, NgClass],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'neurun-fe';
  showBottomNav = true;
  raceUrl: string | null = null;
  isMobile: boolean = false;
  raceLogo: string = '';
  raceWebsite: string = '';
  currentYear: number = 0;

  constructor(private router: Router, private screenSizeService: ScreenResizeService) {}

  ngOnInit() {
    const raceId = localStorage.getItem(RACE_ID);

    this.currentYear = new Date().getFullYear();

    this.screenSizeService.getIsMobile().subscribe(isMobile => {
      this.isMobile = isMobile
    })

    if (raceId) {
      this.raceUrl = `/my-races/race/${raceId}`;
    } else {
      console.error('Race ID not found in local storage');
    }
    this.raceLogo = localStorage.getItem(RACE_LOGO_URL) || '';
    this.raceWebsite = localStorage.getItem(RACE_SITE_URL) || '';

    window.addEventListener('storage', (e) => this.onRaceLogoChange(e))
  }

  onRaceLogoChange(event: StorageEvent) {
    if (event.key === RACE_LOGO_URL && event.newValue) {
      if (event.newValue) {
        this.raceLogo = event.newValue;
      }
    }

    if (event.key === RACE_SITE_URL && event?.newValue) {
      if (event.newValue) {
        this.raceWebsite = event.newValue;
      }
    }
  }

  onRaceWebsiteClick() {
    if (this.raceWebsite) {
      window.open(this.raceWebsite, '_blank')
    }
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.onRaceLogoChange);
  }
}
