<div>
  <span *ngIf="isMobile">
    <app-header
      [title]="race?.name || ''"
      [showAdditionalAction]="true"
      type="settings"
      (handleBackClick)="onBackClick()"
      (handleRightButtonClick)="onSettingsClick()"
    ></app-header>
  </span>

  <div class="chat-wrapper">
    <div #chatContainer class="chat-container" [ngStyle]="{ 'height': requestIndex === 1 && !isInputFocused && isMobile ? '87dvh' : '100dvh' }">
      <div *ngIf="isChatLoading" class="chat-loader-container">
        <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
      </div>

      <ng-container *ngFor="let message of messages; let i = index">
        <div *ngIf="i === 0 || !isSameDay(messages[i-1].sent_at, message.sent_at)" class="date">
          {{ message.sent_at | date: 'MMMM d, yyyy' }}
        </div>
        <div *ngIf="message?.link_accounts_options && message?.link_accounts_options?.length; else defaultTemplate" class="fitness-wrapper">
          <mat-chip-set>
            @for (option of message?.link_accounts_options; track option) {
              <mat-chip class="fitness-container" (click)="onFitnessClick(option.name)">
                <img [src]="option.logo" alt="logo" style="min-height: 25px" />
              </mat-chip>
            }
          </mat-chip-set>
        </div>
        <div *ngIf="message?.table && message?.table!.length > 1" style="margin: 16px 0">
          <div class="table-responsive">
            <div class="mat-elevation-z8">
              <table mat-table [dataSource]="newDataSource" class="mat-table">

                <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                  <th mat-header-cell *matHeaderCellDef
                      [ngStyle]="{
                        'min-width': column === 'Race Km' || column === 'Race Mile' ? '225px' :
                        column === 'Week' ? '150px' : '100px'
                   }">
                    {{ column }}
                  </th>
                  <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </div>
        <ng-template #defaultTemplate>
          <div class="chat-message" [ngClass]="{'ai-message': message.is_system, 'user-message': !message.is_system}">
            <div [ngClass]="{'message-content': !message?.is_options_message && !message?.widget && !message.is_map && message?.text, 'option-message': message?.is_options_message, 'map-message': message?.is_map}">
              <p *ngIf="message?.label && message.text" style="font-weight: 700">{{ message?.label }}</p>
              <div [innerHTML]="message.text"></div>
              <div *ngIf="message?.options?.length && message?.is_options_message" class="link-container">
                <mat-chip-set class="chip-container">
                  @for (option of message?.options; track option) {
                    <mat-chip class="option-container" (click)="onOptionClick(option)" [disabled]="option === 'Choose This Segment' && !segment_coordinates">
                      <span>{{ option }}</span>
                    </mat-chip>
                  }
                </mat-chip-set>
              </div>
              <div *ngIf="message?.gearOptions?.length" class="link-container">
                @for (option of message?.gearOptions; track option) {
                  <a [href]='option?.link' class="link" target="_blank" rel="noopener noreferrer">
                    {{ option?.text }}
                  </a>
                }
              </div>
              <div *ngIf="message.is_map && path && segment_tracks">
                <div style="position: relative; overflow: hidden">
                  <app-routes-map [paths]="path"></app-routes-map>
                  <div style="position: absolute; bottom: 10px">
                    <app-route-swiper [routes]="segment_tracks" [units]="race_units"></app-route-swiper>
                  </div>
                </div>
              </div>
              <div *ngIf="message.loading" class="three-dots-loader">
                <span></span><span></span><span></span>
              </div>
            </div>
          </div>
        </ng-template>
        <div *ngIf="message?.widget">
          <div *ngIf="message.widget === 'SEGMENT_PICKER' && elevationsData?.length">
            <app-elevation-profile [coordinates]="elevationsData" [showSelection]="true" (handleSegmentSelected)="onSegmentSelected($event)" [units]="race_units"></app-elevation-profile>
          </div>
          <div *ngIf="message.widget === 'RADIUS_PICKER' && radius_center_point">
            <app-map-with-radius [startPoint]="radius_center_point || start_point" (handleSelectRadius)="onRadiusSelect($event)" [userUnit]="race_units"></app-map-with-radius>
          </div>
        </div>
      </ng-container>
      <div *ngIf="AIRequestError" style="margin-top: 16px">
        <p style="text-align: center; font-size: 13px; font-weight: 500">There was an error generating a response</p>
        <app-custom-button title="Regenerate Response" variant="secondary" (handleClick)="regenerateResponse()"></app-custom-button>
      </div>
      <div *ngIf="requestIndex && (requestIndex > 1 || requestIndex === -1) && messages?.length" style="margin: 32px 0">
        <div style="display: flex">
          <img ngSrc="assets/images/chat_bubble.svg" alt="AI Chat" height="24" width="24" style="margin-right: 8px"/>
          <span class="section-title">More Prompts:</span>
        </div>
        <div style="margin-top: 12px">
          <mat-chip-set>
            @for (request of filteredAiPrompts; track request; let  i = $index) {
              <mat-chip class="neurun-ai-chip" color="primary" (click)="onAiRequestClick(i + 1)">{{ request }}</mat-chip>
            }
          </mat-chip-set>
        </div>
      </div>

      <div *ngIf="chatError" class="error-message">
        {{ chatError }}
      </div>
    </div>
    <div *ngIf="requestIndex === 1" [ngClass]="{'mobile-container': isMobile}">
      <div class="input-container">
        <input
          class="chat-input"
          (focus)="onInputFocus()"
          [(ngModel)]="userMessage"
          placeholder="Message Neurun AI"
          (keydown.enter)="sendMessage()"
        >
        <button (click)="sendMessage()" class="send-btn" [disabled]="!userMessage.trim()">
          <mat-icon style="font-size: 16px">arrow_upward</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
