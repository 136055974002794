import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent } from "../../components/header/header.component";
import { RaceService } from "../../services/race/race.service";
import { ActivatedRoute, Router } from "@angular/router";
import { GpxWaypoints, Race, RaceGoalPayload, SegmentTracks, Track, TrackCoordinates } from "../../../types/models";
import { DatePipe, DecimalPipe, NgIf, NgOptimizedImage, TitleCasePipe } from "@angular/common";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatChip, MatChipListbox, MatChipOption, MatChipSet } from "@angular/material/chips";
import { MatDivider } from "@angular/material/divider";
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { CustomButtonComponent } from "../../components/custom-button/custom-button.component";
import { MapComponent } from "../../components/map/map.component";
import { MapDirectionComponent } from "../../components/map-direction/map-direction.component";
import { DistanceConverterPipe } from "../../pipes/distance-converter.pipe";
import { TemperatureConverterPipe } from "../../pipes/temperature-converter.pipe";
import {
  AI_REQUESTS,
  RACE_ELEVATION_DATA,
  RACE_ID,
  RACE_LOGO_URL,
  RACE_SITE_URL,
  USER_DATE_FORMAT,
  USER_UNIT_SYSTEM
} from "../../../constants";
import { ElevationProfileComponent } from "../../components/elevation-profile/elevation-profile.component";
import { MapWithRadiusComponent } from "../../components/map-with-radius/map-with-radius.component";
import { ScreenResizeService } from "../../services/screenResive/screen-resize.service";
import { RaceAiChatComponent } from "../race-ai-chat/race-ai-chat.component";
import { MapImmersiveViewComponent } from "../../components/map-immersive-view/map-immersive-view.component";
import { RoutesMapComponent } from "../../components/routes-map/routes-map.component";
import { GeocodingService } from "../../services/geocoding/geocoding.service";
import { GoogleMapsServiceService } from "../../services/google-maps-service/google-maps-service.service";
import { RouteSwiperComponent } from "../../components/route-swiper/route-swiper.component";

type Coordinates = {
  lat: number,
  lng: number
}

@Component({
  selector: 'app-race',
  standalone: true,
  imports: [
    HeaderComponent,
    NgIf,
    NgOptimizedImage,
    TitleCasePipe,
    DatePipe,
    FormsModule,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    MatChipSet,
    MatChip,
    MatDivider,
    DecimalPipe,
    NgxMaskDirective,
    CustomButtonComponent,
    MapComponent,
    MapDirectionComponent,
    DistanceConverterPipe,
    TemperatureConverterPipe,
    ElevationProfileComponent,
    MapWithRadiusComponent,
    RaceAiChatComponent,
    MapImmersiveViewComponent,
    RoutesMapComponent,
    RouteSwiperComponent,
    RoutesMapComponent,
    MatChipListbox,
    MatChipOption
  ],
  providers: [provideNgxMask()],
  templateUrl: './race.component.html',
  styleUrl: './race.component.scss'
})
export class RaceComponent implements OnInit {
  @ViewChild('hiddenSubmitButton') hiddenSubmitButton!: ElementRef<HTMLButtonElement>;
  raceId: string | null = '';
  race: Race | null = null;
  temperature: number = 0;
  raceUnit: string = 'metric';
  userUnitSystem: string = 'metric';
  isEdit: boolean = false;
  start_point: Coordinates | null = null;
  end_point: Coordinates | null = null;
  initialFormValues: any;
  race_goal_guid: string = '';
  path: Coordinates[] | null = null;
  waypoints: GpxWaypoints[] | null = null;
  date_format: string = 'd MMM, y';
  time_format: string = 'HH:mm';
  short_time_format: string = 'H';
  race_image: string | null = '';
  elevationsData: TrackCoordinates[] | null = [];
  isMobile: boolean = false;
  requestIndex: number | null = null;
  isImmersiveViewOpen: boolean = true;
  test_path: Coordinates[][] | null = null

  imageHeight: number = 92; // Starting height (max)
  minHeight: number = 50;   // Minimum height
  maxHeight: number = 92;   // Maximum height
  scrollStart: number = 100;   // Where to start reducing the image height (e.g., at scrollTop = 0)
  scrollEnd: number = 300;

  constructor(private raceService: RaceService, private route: ActivatedRoute, private router: Router, private screenSizeService: ScreenResizeService, private geocodingService: GeocodingService, private googleMapsService: GoogleMapsServiceService) {}

  raceGoalForm = new FormGroup({
    time: new FormControl(),
    comment: new FormControl(),
  })

  ngOnInit(): void {
    const userUnitSystem = localStorage.getItem(USER_UNIT_SYSTEM);
    const userDateFormat = localStorage.getItem(USER_DATE_FORMAT);
    const parsedDateFormat = userDateFormat && JSON.parse(userDateFormat) || 'MM/DD/YYYY'
    this.userUnitSystem = (userUnitSystem && JSON.parse(userUnitSystem)) || 'metric';

    this.screenSizeService.getIsMobile().subscribe(isMobile => {
      this.isMobile = isMobile
    })

    this.route.paramMap.subscribe(params => {
      this.raceId = params.get('race_id');
      setTimeout(() => {
        if (this.raceId) {
          localStorage.setItem(RACE_ID, this.raceId);
        }
      }, 300)
    });

    this.route.queryParams.subscribe(params => {
      const requestIndex = params['request_index'] || null;
      if (requestIndex) {
        this.requestIndex = Number(requestIndex);
      }
    });

    if (this.raceId) {
      this.raceService.getRaceById(this.raceId).subscribe(response => {
        this.race = { ...response, starting_at: response?.starting_at.slice(0, -1) };
        if (this.race?.guid) {
          this.getRaceWeather(this.race?.guid);
          this.getGpxData(this.race?.guid)
        }
        this.race.coordinates
        this.raceUnit = response?.units;
        this.race_image = response?.images[0]?.file_url;
        if (response?.logo?.file_url) {
          localStorage.setItem(RACE_LOGO_URL, response?.logo?.file_url);

          window.dispatchEvent(new StorageEvent('storage', {
            key: RACE_LOGO_URL,
            newValue: this.race.logo?.file_url
          }));
        } else {
          localStorage.removeItem(RACE_LOGO_URL);
        }

        if (response?.website_url) {
          localStorage.setItem(RACE_SITE_URL, response?.website_url);

          window.dispatchEvent(new StorageEvent('storage', {
            key: RACE_SITE_URL,
            newValue: this.race.website_url
          }));
        } else {
          localStorage.removeItem(RACE_SITE_URL);
        }

        if (parsedDateFormat === 'DD/MM/YYYY') {
          this.date_format = 'd MMM, y';
        } else {
          this.date_format = 'MMM d, y';
        }

        if (this.userUnitSystem === 'imperial') {
          this.time_format = 'shortTime'
          this.short_time_format = 'h a';
        } else {
          this.time_format = 'HH:mm';
          this.short_time_format = 'H';
        }

        if (response?.guid) {
          this.raceService.getRaceGoal(response?.guid).subscribe(response => {
            if (response) {
              this.raceGoalForm.get('time')?.setValue(this.msToTimeString(response?.value));
              this.raceGoalForm.get('comment')?.setValue(response?.text);
              this.race_goal_guid = response.guid
            }
          })
        }
      })
    }

    this.raceGoalForm.reset({
      time: {value: '', disabled: true},
      comment: {value: '', disabled: true},
    })

    this.initialFormValues = this.raceGoalForm.value;
  }

  getRaceWeather(guid: string) {
    this.raceService.getRaceWeather(guid).subscribe(response => {
      this.temperature = response?.temperature;
    })
  }

  getGpxData(guid: string) {
    this.raceService.getGpxData(guid).subscribe(response => {
      if (response?.start_point && response?.end_point) {
        if (response?.start_point.lat && response?.start_point.lon) {
          this.start_point = {lat: response.start_point.lat, lng: response.start_point.lon};
          this.path = this.getMappedPath(response.tracks[0])
          this.waypoints = response.waypoints
        }
        if (response?.end_point.lat && response?.end_point.lon) {
          this.end_point = {lat: response.end_point.lat, lng: response.end_point.lon};
        }

        this.elevationsData = response.tracks[0].geometry.coordinates
      }
    })
  }

  hasFormChanged(): boolean {
    return JSON.stringify(this.initialFormValues) !== JSON.stringify(this.raceGoalForm.value);
  }

  onBackClick() {
    this.router.navigate(['/my-races'])
  }

  onChatBackClick() {
    this.requestIndex = null;
  }

  onCustomButtonClick() {
    if (this.raceGoalForm.valid && this.hasFormChanged()) {
      this.hiddenSubmitButton.nativeElement.click();
    }
  }

  getMappedPath(track: Track | GpxWaypoints) {
    if (track) {
      return track?.geometry?.coordinates.map(i => ({ lat: i.lat, lng: i.lon }))
    } else {
      return []
    }
  }

  timeStringToMs(timeString: string): number {
    if (timeString.length !== 6) {
      throw new Error("Invalid time string format. Expected 'hhmmss'.");
    }

    const hours = parseInt(timeString.substring(0, 2), 10);
    const minutes = parseInt(timeString.substring(2, 4), 10);
    const seconds = parseInt(timeString.substring(4, 6), 10);

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      throw new Error("Invalid time string format. Expected 'hhmmss'.");
    }

    const milliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
    return milliseconds;
  }

  msToTimeString(ms: number): string {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  convertToLatLngArray(features: GpxWaypoints[]): Coordinates[] {
    return features.map(feature => {
      const { lat, lon } = feature.geometry.coordinates[0];
      return { lat, lng: lon };
    });
  }

  onEditClick() {
    this.isEdit = true;
    const time = this.raceGoalForm.value.time;
    const comment = this.raceGoalForm.value.comment;
    this.raceGoalForm.reset({
      time: {value: time, disabled: false},
      comment: {value: comment, disabled: false},
    })
  }

  disableRaceGoalFields() {
    this.isEdit = false;
    const time = this.raceGoalForm.value.time;
    const comment = this.raceGoalForm.value.comment;
    this.raceGoalForm.reset({
      time: {value: time, disabled: true},
      comment: {value: comment, disabled: true},
    })
  }

  onSubmit() {
    if (!this.race?.guid) return;
    const data: RaceGoalPayload = {
      value: this.timeStringToMs(this.raceGoalForm.value.time) ?? 0,
      text: this.raceGoalForm?.value.comment ?? '',
    }

    if (this.race_goal_guid) {
      this.raceService.updateRaceGoal(this.race_goal_guid, data).subscribe(() => {
        this.disableRaceGoalFields();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
    } else {
      this.raceService.createRaceGoal(this.race?.guid, data).subscribe(response => {
        this.disableRaceGoalFields();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.race_goal_guid = response?.guid
      })
    }
  }

  onAiRequestClick(value: number) {
    if (this.isMobile) {
      localStorage.setItem(RACE_ELEVATION_DATA, JSON.stringify(this.elevationsData));
      this.router.navigate([`my-races/race/${this.race?.guid}/ai-chat`, { request: value, units: this.raceUnit }])
    } else {
      this.requestIndex = value;
    }
  }

  onLinkClick(url: string) {
    if (url) {
      window.open(url, "_blank");
    }
  }

  onMapViewSwitcherClick(view: string) {
    this.isImmersiveViewOpen = view === 'immersive';
  }

  onRaceOverviewClick(platform: string) {
    let link = '';

    if (platform === 'youtube') {
      link = 'https://www.youtube.com/watch?v=8QVoo_0dT3Y'
    } else if (platform === 'spotify') {
      link = 'https://open.spotify.com/episode/4KBO2qnFZwfdvZp5V132wJ?si=PK-WpimGQo2S7xENL0XSrg';
    } else if (platform === 'applePodcast') {
      link = 'https://podcasts.apple.com/us/podcast/ai-generated-course-overview-podcast-of-the/id1771949208?i=1000671688500';
    }

    if (link) {
      window.open(link, "_blank")
    }
  }

  openChatHistory() {
    this.onAiRequestClick(-1);
  }

  onScroll(event: any) {
    const scrollTop = event.target.scrollTop;

    if (scrollTop <= this.scrollEnd) {
      const scrollRange = this.scrollEnd - this.scrollStart;
      const heightRange = this.maxHeight - this.minHeight;
      const scrollFraction = scrollTop / scrollRange;

      this.imageHeight = this.maxHeight - (scrollFraction * heightRange);

      if (this.imageHeight < this.minHeight) {
        this.imageHeight = this.minHeight;
      }

      if (this.imageHeight > this.maxHeight) {
        this.imageHeight = this.maxHeight;
      }
    }
    console.log(this.imageHeight)
  }

  protected readonly JSON = JSON;
  protected readonly AI_REQUESTS = AI_REQUESTS;
}
