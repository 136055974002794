import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError, from, Observable, throwError } from "rxjs";
import { User, UserFitnessInformation, UserNameInfo, UserSettings } from "../../../types/models";
import axios from "axios";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl: string = environment.baseUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getUser(): Observable<User> {
    const url = `${this.apiUrl}/user/myself`
    return this.http.get<User>(url)
      .pipe(catchError(this.handleError))
  }

  updateUser(data: UserNameInfo | { fitness_information?: UserFitnessInformation, gender?: string, about_me?: string } | UserSettings): Observable<UserNameInfo> {
    const url = `${this.apiUrl}/user/myself`;
    return this.http.put<UserNameInfo>(url, data, this.httpOptions)
      .pipe(catchError(this.handleError))
  }

  uploadUserAvatar(file: FormData): Observable<{url: string}> {
    const url = `${this.apiUrl}/user/avatar`;
    return this.http.post<{url: string}>(url, file)
      .pipe(catchError(this.handleError))
  }

  deleteUser() {
    const url = `${this.apiUrl}/user/myself`;
    return this.http.delete<{url: string}>(url)
      .pipe(catchError(this.handleError))
  }

  getRunkeeperProfileData(token: string) {
    return from(axios.get('https://ai-dev.neurun.com/api-rk/profile', {
      headers: {
        'Content-Type': 'application/vnd.com.runkeeper.Profile+json',
        'Authorization': `Bearer ${token}`,
        'accept': '*/*'
      }
    }))
  }

  getRunkeeperUserWeightData(token: string) {
    return from(axios.get('https://ai-dev.neurun.com/api-rk/weight', {
      headers: {
        'Content-Type': 'application/vnd.com.runkeeper.Weight+json',
        'Authorization': `Bearer ${token}`,
        'accept': '*/*'
      }
    }))
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => errorMessage);
  }
}
