<div *ngIf="isMobile; else desktopView">
  <div class="content">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="showBottomNav" class="bottom-nav">
    <!--  <div class="nav-btn-container">-->
    <!--    <button routerLink="/find-a-race" class="nav-btn" routerLinkActive="mat-selected">-->
    <!--      <img src="/assets/images/search-icon.svg" alt="Search">-->
    <!--      <span class="nav-label">Find a Race</span>-->
    <!--    </button>-->
    <!--  </div>-->
    <div class="nav-btn-container">
      <button
        [routerLink]="raceUrl"
        class="nav-btn"
        routerLinkActive="mat-selected"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <img src="/assets/images/sprint-icon.svg" alt="Event">
        <span class="nav-label">My Event</span>
      </button>
    </div>
<!--    <div class="nav-btn-container">-->
<!--      <button routerLink="/saved" class="nav-btn" routerLinkActive="mat-selected">-->
<!--        <img src="/assets/images/favorite-icon.svg" alt="Saved">-->
<!--        <span class="nav-label">Saved</span>-->
<!--      </button>-->
<!--    </div>-->
    <div class="nav-btn-container">
      <button routerLink="/profile" class="nav-btn" routerLinkActive="mat-selected">
        <img src="/assets/images/account_circle-icon.svg" alt="Profile">
        <span class="nav-label">Profile</span>
      </button>
    </div>
  </div>
</div>
<ng-template #desktopView>
  <div class="desktop-nav">
    <div *ngIf="raceLogo && raceLogo !== 'undefined'; else emptyBlock" (click)="onRaceWebsiteClick()" style="cursor: pointer">
      <img [src]="raceLogo" alt="Race logo" style="width: 92px; height: 92px; object-fit: contain" />
    </div>
    <div style="display: flex; gap: 100px">
      <div class="nav-btn-container">
        <button
          [routerLink]="raceUrl"
          class="nav-btn"
          routerLinkActive="mat-selected"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <img src="/assets/images/sprint-icon.svg" alt="Event">
          <span class="nav-label">My Event</span>
        </button>
      </div>
<!--      <div class="nav-btn-container">-->
<!--        <button routerLink="/saved" class="nav-btn" routerLinkActive="mat-selected">-->
<!--          <img src="/assets/images/favorite-icon.svg" alt="Saved">-->
<!--          <span class="nav-label">Saved</span>-->
<!--        </button>-->
<!--      </div>-->
      <div class="nav-btn-container">
        <button routerLink="/profile" class="nav-btn" routerLinkActive="mat-selected">
          <img src="/assets/images/account_circle-icon.svg" alt="Profile">
          <span class="nav-label">Profile</span>
        </button>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 8px">
      <img src="assets/images/rr-logo.svg" alt="Race Roster" />
      <img src="assets/images/powered-by-neurun-ai.svg" alt="Neuran AI" />
    </div>
  </div>
  <ng-template #emptyBlock>
    <div style="width: 92px; height: 92px"></div>
  </ng-template>
  <div class="content">
    <router-outlet></router-outlet>
<!--    <div class="footer">-->
<!--      <div style="display: flex; justify-content: space-between; align-items: center">-->
<!--        <span>Support & Feedback</span>-->
<!--        <span>© Neurun, Inc. {{ currentYear }}</span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</ng-template>
