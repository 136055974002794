import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexMarkers,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  NgApexchartsModule, ApexGrid
} from 'ng-apexcharts';
import { SegmentCoordinates, TrackCoordinates } from '../../../types/models';
import { NgIf } from "@angular/common";
import {MatError, MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NgxMaskDirective} from "ngx-mask";
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import { debounceTime, Subject } from "rxjs";

interface LatLng {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-elevation-profile',
  standalone: true,
  imports: [
    NgApexchartsModule,
    NgIf,
    MatFormField,
    MatInput,
    NgxMaskDirective,
    ReactiveFormsModule,
    MatError,
  ],
  templateUrl: './elevation-profile.component.html',
  styleUrls: ['./elevation-profile.component.scss']
})
export class ElevationProfileComponent implements OnInit {
  @Input() coordinates: TrackCoordinates[] | null = [];
  @Input() units: string = '';
  @Input() showSelection: boolean = false;
  @Output() handleSegmentSelected = new EventEmitter<{ start: LatLng, end: LatLng } | null>();

  public series: ApexAxisChartSeries = [];
  public chart!: ApexChart;
  public dataLabels!: ApexDataLabels;
  public markers!: ApexMarkers;
  public title!: ApexTitleSubtitle;
  public fill!: ApexFill;
  public yaxis!: ApexYAxis;
  public xaxis!: ApexXAxis;
  public tooltip!: ApexTooltip;
  public grid!: ApexGrid;
  public segmentCoordinates: SegmentCoordinates | null = null;
  public maxChartValue: number = 0;
  public quarterSelectionValue: number = 0;
  public reInitChart: boolean = false;
  private programmaticChange: boolean = false;
  private selectionSubject = new Subject<{ min: number, max: number }>();

  constructor(private cdr: ChangeDetectorRef) {}

  elevationForm = new FormGroup({
    start: new FormControl('', [Validators.required, Validators.min(0)]),
    finish: new FormControl('', [Validators.required, Validators.min(0)])
  }, this.startFinishValidator(this.units));

  get start() { return this.elevationForm.get('start'); }
  get finish() { return this.elevationForm.get('finish'); }

  ngOnInit() {
    this.initChartData({ min: 0, max: 0 });
    this.elevationForm.get('start')?.setValue('0');
    const finishValue = this.units === 'imperial' ? '1' : '1.5';
    this.elevationForm.get('finish')?.setValue(finishValue);

    // Subscribe to form changes
    // this.elevationForm.valueChanges.subscribe(values => {
    //   // Only call onElevationFormChange if the form change is from user input
    //   if (!this.programmaticChange) {
    //     this.onElevationFormChange(values);
    //   }
    // });

    this.elevationForm.valueChanges.subscribe(values => {
      if (!this.programmaticChange) {
        this.handleManualInputChange(values);
      }
    });

    this.selectionSubject
      .pipe(debounceTime(1500))
      .subscribe(({ min, max }) => {
        // this.segmentCoordinates = { min, max };
        this.updateFormValues(min, max);
      });
  }

  private updateFormValues(min: number, max: number): void {
    this.programmaticChange = true;
    this.elevationForm.get('start')?.setValue(min.toString());
    this.elevationForm.get('finish')?.setValue(max.toString());
    this.programmaticChange = false;

    this.segmentCoordinates = { min, max };
  }

  private handleManualInputChange(values: any): void {
    if (this.elevationForm.valid) {
      const startValue = Number(values.start);
      const finishValue = Number(values.finish);

      if (startValue >= 0 && finishValue >= 0 && startValue < finishValue && startValue <= this.maxChartValue && finishValue <= this.maxChartValue) {
        this.reInitChart = true;
        this.initChartData({ min: startValue, max: finishValue });
      }
    } else {
      this.handleSegmentSelected.emit(null);
    }
  }

  startFinishValidator(units: string) {
    return (group: AbstractControl) => {
      const start = group.get('start')?.value;
      const finish = group.get('finish')?.value;
      const maxValue = units === 'imperial' ? 1 : 1.5;

      if (start !== null && finish !== null && Math.abs(finish - start) > maxValue) {
        return { 'rangeError': true };
      }
      return null;
    }
  }

  onElevationFormChange(values: any): void {
    if (this.elevationForm.valid) {
      const startValue = Number(values.start);
      const finishValue = Number(values.finish);

      if (startValue >= 0 && finishValue >= 0 && startValue < finishValue && startValue <= this.maxChartValue && finishValue <= this.maxChartValue) {
        setTimeout(() => {
          this.initChartData({ min: startValue, max: finishValue });
        }, 1000)
      }
    } else {
      this.handleSegmentSelected.emit(null);
    }
  }

  haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Earth radius in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  private calculateSlope(startIndex: number, endIndex: number, points: TrackCoordinates[]): number {
    const startPoint = points[startIndex];
    const endPoint = points[endIndex];
    const distance = this.haversineDistance(startPoint.lat, startPoint.lon, endPoint.lat, endPoint.lon);

    if (distance === 0) return 0;
    const elevationChange = (endPoint.ele ?? 0) - (startPoint.ele ?? 0);

    return 0.1 * (elevationChange / distance);
  }

  private mapPointsToChartData(points: TrackCoordinates[]): { x: number, y: number, lat: number, lon: number, slope?: number }[] {
    let cumulativeDistance = 0;
    const chartData = [];

    const distanceConversionFactor = this.units === 'imperial' ? 0.621371 : 1;
    const elevationConversionFactor = this.units === 'imperial' ? 3.28084 : 1;

    for (let i = 0; i < points.length; i++) {
      let slope = 0;

      if (i > 0) {
        const prevPoint = points[i - 1];
        const currentPoint = points[i];
        const distance = this.haversineDistance(prevPoint.lat, prevPoint.lon, currentPoint.lat, currentPoint.lon);
        cumulativeDistance += distance;

        slope = this.calculateSlope(i - 1, i, points);
      }

      const distanceInUnits = cumulativeDistance * distanceConversionFactor;
      const elevationInUnits = points[i].ele * elevationConversionFactor;

      chartData.push({
        x: Math.round(distanceInUnits * 1e2) / 1e2,
        y: Math.round(elevationInUnits * 1e2) / 1e2,
        lat: points[i].lat,
        lon: points[i].lon,
        slope: Math.round(slope * 1e2) / 1e2
      });
    }

    return chartData;
  }

  public initChartData(selection: { min: number, max: number }): void {
    if (!this.coordinates?.length) return;
    const chartData = this.mapPointsToChartData(this.coordinates);
    const quarterIndex = Math.floor(chartData.length / 4);
    this.quarterSelectionValue = chartData[quarterIndex]?.x;
    this.maxChartValue = chartData[chartData?.length - 1]?.x;

    this.series = [{
      name: 'Elevation',
      data: chartData
    }];

    this.grid = {
      show: false
    };

    this.chart = {
      type: 'area',
      height: 175,
      animations: {
        enabled: false
      },
      toolbar: {
        tools: {
          download: false,
          zoom: false,
          pan: false,
          zoomin: false,
          zoomout: false,
          reset: false
        },
        autoSelected: "selection",
      },
      zoom: {
        enabled: false
      },

      events: {
        selection: (chartContext, { xaxis }) => {
          const min = xaxis?.min;
          const max = xaxis?.max;

          this.selectionSubject.next({ min, max });
          this.segmentCoordinates = { min, max };
          this.emitSegmentCoordinates(chartData);
          this.cdr.detectChanges();
        }
      },
      selection: {
        enabled: this.showSelection,
        type: 'x',
        stroke: {
          width: 1,
          dashArray: 3,
          color: '#6271FF',
          opacity: 0.5
        },
        xaxis: {
          min: selection?.min,
          max: selection.max || 1
        }
      }
    };

    this.dataLabels = {
      enabled: false
    };

    this.markers = {
      size: 0
    };

    this.title = {
      text: 'Elevation Profile',
      align: 'left'
    };

    this.yaxis = {
      min: (val: number) => val - val,
      axisBorder: {
        show: true,
        color: '#C3C3C3'
      },
      labels: {
        formatter: (val: number): string | string[] => {
          return this.units === 'imperial' ? `${val} ft` : `${val} m`;
        }
      }
    };
    this.xaxis = {
      tickAmount: 4,
      axisBorder: {
        show: true,
        color: '#C3C3C3'
      },
    };

    this.tooltip = {
      intersect: this.showSelection,
      shared: false,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const point = w.globals.series[seriesIndex][dataPointIndex];
        const slope = w.globals.initialSeries[seriesIndex].data[dataPointIndex].slope;

        const slopeOutput = `Grade: <strong style="margin-left: 25px">${slope.toFixed(1)}%</strong>`;
        const elevationOutput = this.units === 'imperial'
          ? `${point.toFixed(1)} ft`
          : `${point.toFixed(1)} m`;

        return `
      <span style="padding: 10px; background: rgba(255, 255, 255, 0.7)!important">
        Elevation: <strong>${elevationOutput}</strong><br/>
        ${slopeOutput}
      </span>`;
      },
      x: {
        formatter: (val: number): string => {
          return this.units === 'imperial' ? `${val.toFixed(2)} mi` : `${val.toFixed(2)} km`;
        }
      },
    };
  }

  private emitSegmentCoordinates(chartData: { x: number, y: number, lat: number, lon: number }[]): void {
    if (this.segmentCoordinates) {
      // @ts-ignore
      const startPoint = chartData.find(data => data.x >= this.segmentCoordinates?.min);
      // @ts-ignore
      const endPoint = chartData.find(data => data.x >= this.segmentCoordinates?.max);
      if (startPoint && endPoint) {
        this.handleSegmentSelected.emit({ start: { lat: startPoint.lat, lng: startPoint.lon }, end: { lat: endPoint.lat, lng: endPoint.lon } });
      }
    }
  }
}

